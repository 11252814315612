import {Controller} from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
    static values = {
        run: String,
        refresh: Number,
        location: String,
        decideSolution: Number
    }

    connect() {
        let sweetalertController = this
        this.element[this.identifier] = this
        StimulusReflex.register(sweetalertController)
        if (this.runValue === 'confirm') {
            this.confirm(new Event(''))
        } else if (this.runValue === 'remove') {
            this.remove(new Event(''))
        }
    }

    remove(event) {
        let sweetalertController = this
        StimulusReflex.register(sweetalertController)
        event.preventDefault()
        let $targetElement = $(sweetalertController.data.element);
        var title = sweetalertController.element.getAttribute("data-title") ? sweetalertController.element.getAttribute("data-title") : 'Are you sure?'
        var text = sweetalertController.element.getAttribute('data-html') ? sweetalertController.element.getAttribute('data-html') : "You won't be able to revert this!"
        var confirm_btn_text = sweetalertController.element.getAttribute('data-confirm-btn-text') ? sweetalertController.element.getAttribute('data-confirm-btn-text') : 'Delete'

        Swal.fire({
            customClass: {
                confirmButton: 'btn btn-danger ms-2 order-2',
                cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
            title: '<i class="bi bi-exclamation-triangle-fill bg-danger-10 text-danger d-flex justify-content-center align-items-center rounded-circle me-3"></i> ' + title,
            html: text,
            showCancelButton: true,
            confirmButtonText: confirm_btn_text,
        }).then((result) => {
            if (result.isConfirmed) {
                document.body.classList.remove('swal2-shown', 'swal2-height-auto')
                if (sweetalertController.data.get("stimulateaction")) {
                    sweetalertController.stimulate(sweetalertController.data.get("stimulateaction"))
                } else {
                    $targetElement.parents('form').submit();
                }
            }
        })
    }

    confirm(event) {
        let sweetalertController = this;
        let $targetElement = $(sweetalertController.data.element);
        if (event) {
            event.preventDefault();
        }

        Swal.fire({
            customClass: {
                confirmButton: sweetalertController.element.getAttribute('data-custom-confirm-class') ? sweetalertController.element.getAttribute('data-custom-confirm-class') : 'btn btn-primary ms-2 order-2',
                cancelButton: sweetalertController.element.getAttribute('data-custom-cancel-class') ? sweetalertController.element.getAttribute('data-custom-cancel-class') : 'btn btn-outline-primary',
            },
            buttonsStyling: false,
            title: sweetalertController.element.getAttribute('data-title'),
            html: sweetalertController.element.getAttribute('data-html'),
            showCancelButton: true,
            confirmButtonText: sweetalertController.element.getAttribute('data-confirm-text'),
            cancelButtonText: $targetElement.data('cancel-text') ? $targetElement.data('cancel-text') : 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                if (sweetalertController.element.getAttribute('data-show-success-msg')) {
                    let e = sweetalertController.element
                    let title = e.getAttribute('data-success-title') || e.getAttribute('data-success-msg')
                    let html = e.getAttribute('data-success-title') ? e.getAttribute('data-success-msg') : ''
                    let confirmButtonText = e.getAttribute('data-success-btn') || 'Ok'
                    if (sweetalertController.data.get("stimulateaction")) {
                        sweetalertController.stimulate(sweetalertController.data.get("stimulateaction"))
                        Swal.fire({
                            title: title,
                            html: html,
                            cancelButtonText: cancelButtonText,
                            confirmButtonText: confirmButtonText,
                        })
                    } else {
                        Swal.fire({
                            title: title,
                            html: html,
                            confirmButtonText: confirmButtonText,
                            cancelButtonText: cancelButtonText,
                        }).then((result) => {
                            $targetElement.parents('form').submit();
                        })
                    }
                } else {
                    document.body.classList.remove('swal2-shown', 'swal2-height-auto')
                    if (sweetalertController.data.get("stimulateaction")) {
                        sweetalertController.stimulate(sweetalertController.data.get("stimulateaction"))
                    } else {
                        $targetElement.parents('form').submit();
                    }
                }
            } else {
                let form = sweetalertController.data.element.closest('form');
                if (form) {
                    let submitButton = form.querySelector('[type="submit"]')
                    if (submitButton) {
                        submitButton.disabled = false
                    }
                }
                if (sweetalertController.data.get("stimulateaction-cancel") != undefined) {
                    sweetalertController.stimulate(sweetalertController.data.get("stimulateaction-cancel"))
                }
            }
        })
    }

    refreshValueChanged() {
        if (this.refreshValue === 1) {
            this.refreshValue = 0;
            let sweetalertController = this;
            let $targetElement = $(sweetalertController.data.element);

            Swal.fire({
                customClass: {
                    confirmButton: sweetalertController.element.getAttribute('data-custom-confirm-class') ? sweetalertController.element.getAttribute('data-custom-confirm-class') : 'btn btn-primary ms-2 order-2',
                    cancelButton: sweetalertController.element.getAttribute('data-custom-cancel-class') ? sweetalertController.element.getAttribute('data-custom-cancel-class') : 'btn btn-outline-primary',
                },
                buttonsStyling: false,
                title: sweetalertController.element.getAttribute('data-title'),
                html: sweetalertController.element.getAttribute('data-html'),
                showCancelButton: $targetElement.data('cancel-text') ? true : false,
                confirmButtonText: $targetElement.data('confirm-text') ? $targetElement.data('confirm-text') : 'Ok',
                cancelButtonText: $targetElement.data('cancel-text') ? $targetElement.data('cancel-text') : 'Cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (this.locationValue === '') return
                    document.location = this.locationValue
                } else {
                    if (sweetalertController.data.get("stimulateaction-cancel") != undefined) {
                        sweetalertController.stimulate(sweetalertController.data.get("stimulateaction-cancel"))
                    }
                }
            })
        }
    }

    redirect(event) {
        let sweetalertController = this;
        let $targetElement = $(sweetalertController.data.element);
        if (event) {
            event.preventDefault();
        }

        Swal.fire({
            customClass: {
                confirmButton: sweetalertController.element.getAttribute('data-custom-confirm-class') ? sweetalertController.element.getAttribute('data-custom-confirm-class') : 'btn btn-primary ms-2 order-2',
                cancelButton: sweetalertController.element.getAttribute('data-custom-cancel-class') ? sweetalertController.element.getAttribute('data-custom-cancel-class') : 'btn btn-outline-primary',
            },
            buttonsStyling: false,
            title: sweetalertController.element.getAttribute('data-title'),
            html: sweetalertController.element.getAttribute('data-html'),
            showCancelButton: true,
            confirmButtonText: $targetElement.data('confirm-text'),
            cancelButtonText: $targetElement.data('cancel-text') ? $targetElement.data('cancel-text') : 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.locationValue === '') return
                document.location = this.locationValue
            } else {
                if (sweetalertController.data.get("stimulateaction-cancel") != undefined) {
                    sweetalertController.stimulate(sweetalertController.data.get("stimulateaction-cancel"))
                }
            }
        })
    }

    notification() {
        let sweetalertController = this;

        Swal.fire({
            title: sweetalertController.element.getAttribute('data-title'),
            html: sweetalertController.element.getAttribute('data-html'),
        })
    }

    decideSolutionValueChanged() {
        if (this.decideSolutionValue == 1) {
            this.decideSolutionValue = 0;
            this.confirm()
        }
    }
}
