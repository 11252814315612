import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Cohere from "cohere-js";

export default class extends Controller {
    connect() {
        if (this.element.getAttribute('data-disable-cohere') == 'true') {
            Cohere.init(this.element.getAttribute('data-token'), {disableRecording: true});
        } else {
            Cohere.init(this.element.getAttribute('data-token'));
            Cohere.identify(
                this.element.getAttribute('data-user-id'), {
                    displayName: this.element.getAttribute('data-user-name'),
                    email: this.element.getAttribute('data-email'),
                })
        }
    }
}